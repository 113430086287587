.populer {
    /* background:#f0f2f5; */
    margin-bottom: 10px;
    border-radius: 5px;
}

.title {
    border-bottom: 2px solid #4164b2;
    margin: 5px 15px 7px;
    float: left;
    font-size: 20px;
    width: calc(100% - 25px);
    font-weight: 700;
    color: #000;
    font-family: trebuchet ms, Helvetica, sans-serif;

}

.title-desc {
    margin: 6px 15px 0;
    font-size: 12px;
    text-align: justify;
}

.sub-title {
    margin: 15px 15px !important;
    margin-bottom: 15px !important;
    font-size: 17px;
    font-weight: 600;
}