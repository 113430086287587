.footer {
    text-align: center;
  }
  
  .line {
    border-top: 1px solid #ccc; /* Warna garis abu-abu */
    margin-bottom: 10px; /* Jarak antara garis dan teks */
    margin-top: 30px; /* Jarak antara garis dan teks */
  }
  
  .copyright {
    font-size: 14px;
    color: #666; /* Warna teks abu-abu */
  }
  