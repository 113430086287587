.about-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    font-family: Arial, sans-serif;
  }
  
  .about-container h1 {
    text-align: center;
    color: #333;
  }
  
  .about-container p {
    text-align: justify;
    font-size: 18px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-container strong {
    color: #000;
  }
  