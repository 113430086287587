/* Scroll Up */
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -3rem; /* Mulai tersembunyi di luar layar */
  background-color: #c8dae8;
  opacity: 0.7; /* Lebih transparan */
  padding: 0.1rem 0.1rem;
  border-radius: 0.4rem;
  z-index: 1000; /* Menentukan z-index */
  transition: bottom 0.4s, opacity 0.4s; /* Animasi yang lebih halus */
  height: 50px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: black; /* Ubah warna sesuai keinginan */
}

/* Saat hover pada tombol */
.scrollup:hover {
  background-color: #3e4d59;
  opacity: 1;
}

/* Saat tombol ditekan (klik) */
.scrollup:active {
  background-color: #c8dae8; /* Kembali ke warna asal */
  opacity: 0.7; /* Kembali ke opacity awal */
}

/* Tampilkan tombol ketika halaman di-scroll */
.show-scroll {
  bottom: 2rem; /* Naik ke atas layar */
}
