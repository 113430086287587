.container-h1 {
  text-align: center; /* Mengatur teks agar rata tengah */
  margin-top: -30px;
}

.detail-item-status {
  text-align: center; /* Mengatur teks agar rata tengah */
  font-weight: 600;
  font-size: large;
}

.status-ongoing {
  color: green;
}

.status-complete {
  color: red;
}

.status-unknown {
  color: gray;
}

.sinopsis-detail {
  text-align: justify;
  padding: 20px;
}

.comic-detail-container {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

.cover-image-container {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: -1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Full viewport height */
}

.cover-image {
  width: 100%;
  height: auto;
  object-fit: scale-down;
  border-radius: 10px;
}

.button-container {
  position: fixed;
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  gap: 10px;
  width: 500px;
}

/* Media query untuk tampilan mobile */
@media (max-width: 768px) {
  /* Sesuaikan dengan breakpoint yang diinginkan */
  .button-container {
    width: 350px; /* Lebar untuk mobile */
  }
  .comic-detail-info,
  .comic-detail-chapters {
    padding: 20px;
  }
}

.comic-detail-info,
.comic-detail-chapters {
  z-index: 2; /* Ensure text is above the background image */
  background: rgba(
    255,
    255,
    255,
    0.9
  ); /* Add a slight background to ensure readability */
  /* padding: 10px 400px 0px 400px; */
  padding: 20px;
}

/* .blank-space {
  height: 50px;
} */

.read-button {
  background-color: #4caf50; /* Warna hijau dengan kecerahan sedang */
  color: white;
  font-weight: bold; /* Font tebal */
  font-size: 12px; /* Ukuran font kecil namun jelas */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.read-button::before {
  content: "";
  position: absolute;
  top: -50%;
  right: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.3s ease-in-out;
}

.read-button:hover::before {
  transform: translateX(50%) translateY(50%);
}

.read-button:hover {
  background-color: #45a049; /* Warna hijau sedikit lebih gelap saat hover */
}

.later-button {
  background-color: rgb(184, 179, 179); /* Warna merah muda pekat */
  color: white;
  font-weight: bold; /* Font tebal */
  font-size: 12px; /* Ukuran font kecil namun jelas */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.later-button:hover {
  background-color: #353333; /* Warna merah muda sedikit lebih gelap saat hover */
}

.read-more {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: rgb(61, 61, 253);
  font-weight: bold;
}

.read-more:hover {
  text-decoration: underline;
}

.read-more-text {
  font-weight: bold;
}

.chapter-list-ul {
  list-style-type: none;
  padding: 0;
}

.chapter-list-item {
  margin-bottom: 5px;
}

.chapter-button {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.detail-item-genre {
  text-align: center;
}

.genre-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.genre-button:hover {
  background-color: #e0e0e0;
}

.genre-button:focus {
  outline: none;
  box-shadow: 0 0 2px 2px #a0a0a0;
}
