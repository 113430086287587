.button-open-list {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  color: #1e90ff;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-decoration-line: underline
}
