.subheader-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Menyusun tombol ke sisi kiri */
  height: 80px; /* Setinggi h-20 */
  gap: 5px; /* Setara dengan lg:gap-4 */
  overflow-x: auto; /* Aktifkan scroll horizontal jika menu melebihi lebar layar */
  white-space: nowrap; /* Mencegah tombol membungkus */
  font-weight: 600;
  padding: 10px;
}

.subheader-button {
  background-color: #3b82f6; /* Setara dengan bg-blue-500 */
  color: white; /* Setara dengan text-white */
  padding: 8px 12px; /* Setara dengan py-2 px-3 */
  border-radius: 8px; /* Setara dengan rounded */
  font-size: 14px; /* Setara dengan text-sm */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subheader-button:hover {
  background-color: #2563eb; /* Setara dengan hover:bg-blue-600 */
}

/* Responsive styles */
@media (min-width: 640px) {
  .subheader-container {
    font-size: 16px; /* Setara dengan sm:text-base */
  }
}

@media (min-width: 1024px) {
  .subheader-container {
    justify-content: center; /* Rata tengah tombol pada layar besar */
  }
  .subheader-button {
    font-size: 16px; /* Setara dengan lg:text-base */
  }
}

html {
  scroll-behavior: smooth;
}
