.populer {
    background: #ffffff;
    margin-bottom: 10px;
    /* border-width: 10px;
    border-radius: 10px; */
}

.title {
    border-bottom: 2px solid #4164b2;
    margin: 5px 5px 7px;
    float: left;
    font-size: 24px;
    width: calc(100% - 25px);
    font-weight: 700;
    color: #000;
    font-family: trebuchet ms, Helvetica, sans-serif;

}

.title-desc {
    margin: 6px 5px 0;
    font-size: 12px;
    text-align: justify;
}

.sub-title {
    margin: 15px 15px !important;
    margin-bottom: 15px !important;
    font-size: 17px;
    font-weight: 600;
}

.sub-title-weekly {
    margin: 15px 15px !important;
    margin-bottom: 15px !important;
    font-size: 24px;
    font-weight: 700;
}