

/* Untuk mobile, container akan memenuhi seluruh layar */
.container {
  /* width: 100%; */
  max-width: 1280px !important;
  margin: 0 auto;
  padding: 0 5px; /* Sedikit padding untuk mobile */
  /* background-color: #f0f2f5; */
}

.base {
  background:#f0f2f5;
}


/* Untuk desktop, beri ruang di kiri dan kanan */
@media (min-width: 768px) {
  .container {
    width: 80%; /* Atur sesuai keinginan, misalnya 80% dari lebar layar */
    max-width: 1200px; /* Agar tidak terlalu lebar di layar besar */
    margin: 0 auto;
    padding: 0; /* Hilangkan padding untuk desktop */
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;

