.search-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding-top: 0px; /* Menambahkan jarak dari atas */
}

.search-box {
    width: 90%;
  }

.search-results {
    margin-top: 20px; /* Menambahkan margin atas */
    /*padding: 0 20px; /* Menambahkan padding kiri dan kanan */
}

.input-cari {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }

.search-result-item {
    margin-right: -3px;
    margin-bottom: 10px;
    margin-left: 0px;
    font-variant: all-small-caps;
}

/* Mengatur ukuran gambar untuk ponsel */
@media only screen and (max-width: 768px) {
    .search-result-item img {
        width: 100px; /* Ukuran gambar untuk layar ponsel */
        height: 150px; /* Ukuran gambar untuk layar ponsel */
    }
}

/* Mengatur ukuran gambar untuk desktop */
@media only screen and (min-width: 769px) {
    .search-result-item img {
        width: 200px; /* Ukuran gambar untuk layar desktop */
        height: 300px; /* Ukuran gambar untuk layar desktop */
    }
}


.loading-screen {
    text-align: center;
    margin-top: 20px;
  }